// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/fadeIn/styles.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,2BAA2B;EAC3B,kBAAkB;EAClB;8BAC4B;EAC5B,2CAA2C;AAC7C;AACA;EACE,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB","sourcesContent":["\n.fade-in-section {\n  opacity: 0;\n  transform: translateY(20vh);\n  visibility: hidden;\n  transition: opacity 1200ms ease-out, transform 600ms ease-out,\n    visibility 1200ms ease-out;\n  will-change: opacity, transform, visibility;\n}\n.fade-in-section.is-visible {\n  opacity: 1;\n  transform: none;\n  visibility: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
