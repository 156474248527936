
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';


const WhatsAppButton = () => {
  const phoneNumber = '+447399993232'; // Replace with your WhatsApp number

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <FaWhatsapp className="whatsapp-icon" />
    </div>
  );
};

export default WhatsAppButton;