// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Kiona-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Adam-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Adam-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Adam-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Avenir-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Avenir-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Avenir-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Kiona";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Custom Font: Adam-Bold */

@font-face {
  font-family: 'Adam-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Custom Font: Adam-Light */
@font-face {
  font-family: 'Adam-Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Custom Font: Adam-Medium */
@font-face {
  font-family: 'Adam';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('opentype');
  font-weight: normal;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,+DAAkD;EAClD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,2BAA2B;;AAE3B;EACE,wBAAwB;EACxB,+DAA8C;EAC9C,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA,4BAA4B;AAC5B;EACE,yBAAyB;EACzB,+DAA+C;EAC/C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,6BAA6B;AAC7B;EACE,mBAAmB;EACnB,+DAAgD;EAChD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,+DAAmD;EACnD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,+DAAgD;EAChD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,+DAAiD;EACjD,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Kiona\";\n  src: url(\"./Kiona-Regular.ttf\") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n/* Custom Font: Adam-Bold */\n\n@font-face {\n  font-family: 'Adam-Bold';\n  src: url('./Adam-Bold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n\n/* Custom Font: Adam-Light */\n@font-face {\n  font-family: 'Adam-Light';\n  src: url('./Adam-Light.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n/* Custom Font: Adam-Medium */\n@font-face {\n  font-family: 'Adam';\n  src: url('./Adam-Medium.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir';\n  src: url('./Avenir-Regular.otf') format('opentype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir-Bold';\n  src: url('./Avenir-Bold.otf') format('opentype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir-Light';\n  src: url('./Avenir-Light.otf') format('opentype');\n  font-weight: normal;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
