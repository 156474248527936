const config = {
  API_URL: 'http://localhost:5000/api',
  STRAPI_URL: 'http://localhost:1337',
  MAPS_API_KEY: 'AIzaSyBoZFGXOCwer9dv34IPMOhFqlApLBQtprs',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_51NaN27KRJu2e9UD4Y8LuP52Mlb6VyUFTjfsLKCOsU66F80zhBNpfA1IKh0CnEBxYZsg645Kqzr2NLgj9DIjhnHzi00kJPJxInm'
};

export default config;


