import React, { useEffect, useRef, useState } from "react";
import axios from './components/axios';
import config from "./config/config";

export const getCoupons = async () => {
  try {
    const response = await axios.get(`${config.STRAPI_URL}/api/coupons?populate=*`, {})
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

function CouponModel({ coupon = 'E TAILOFFER25' }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(false);
  const [coupons, setCoupons] = useState(null);

  useEffect(() => {
    if (ref.current) return;
    ref.current = true;

    setTimeout(() => {
      getCoupons().then((res) => {
        const couponData = res.data;
        //  console.log('Coupon status:', couponData[0].attributes.status);
        if (couponData[0].attributes.status === 'active' && couponData.length > 0) {
          setCoupons(couponData[0].attributes);
          setOpen(true);
        }
      }).catch((err) => {
        // Handle error if needed
      });
    }, 5000);
  }, []);

  // const couponData = coupons && coupons.length > 0 ? coupons[0].attributes : null;

  return (
    <>
      {open && coupons && (
        <>
          <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-50 z-[100]" onClick={() => setOpen(false)}></div>
          <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] text-[#000] bg-[#fff] mx-auto top-0 left-0 p-8 rounded-lg shadow-lg md:w-[600px] xs:w-[300px] lg:px-12" style={{ color: `${coupons.text_color}`, backgroundColor: `${coupons.bg_color}` }}>
            <div onClick={() => setOpen(false)} className="font-bold cursor-pointer absolute top-5 right-5">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 border border-primary rounded-md text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div className="flex md:flex-row flex-col justify-between">
              <div className="flex flex-col justify-center items-center">
                <div className="text-3xl text-sm self-start font-bold mb-4">{coupons.Title}</div>
                {/* <div className="text-lg mb-4">
                  Get <span className="text-yellow-400 font-bold">25% OFF</span> your next booking!
                </div> */}
                <div className="text-lg text-xs self-start mb-4">
                  {coupons.description}
                </div>
                <div className="text-base text-xs self-start mb-4">Use coupon code:</div>
                <div className="bg-[#e5e5e5] text-gray-800 rounded-lg px-4 py-2 w-full flex items-center justify-between">
                  <span className="text-2xl text-xs font-semibold">{coupons.coupon_code}</span>
                  <button onClick={() => { navigator.clipboard.writeText(coupons.coupon_code) }} className="bg-blue-800 text-white text-xs px-3 py-1 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                    Copy
                  </button>
                </div>
                <div className="text-sm text-xs self-start mt-4">
                  <p>
                    Valid until: <span className="font-semibold">{new Date(coupons.expires_at).toLocaleDateString()}</span>
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center md:w-[200px]">
                {/* Include the image using the URL */}
                <img src={`${config.STRAPI_URL}` + coupons.image?.data?.attributes?.url || ""} alt="Coupon Image" className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CouponModel;
